$container-width: 1230px;
$container-padding: 0 15px;
$spacer: 16px;
$row-margin: 0;
//colors
$white: #FFFFFF;
$black: #000000;
$black2: #272727;
$black3: #3f3f3f;
$yellow: #edf008;
$blue: #41c0de;
$pink: #ff00bf;
$red: #790000;
//fonts
$generica: 'genericaregular';
$generica-bold: 'genericabold';
$jennasue: 'jenna_sueregular';
$arial : Arial, Helvetica Neue, Helvetica, sans-serif;

$max-desk: 1200px;
$min-desk: 1160px;
$menu-responsive: 1040px;
$max-tab: 940px;
$min-tab: 892px;
$wp-mobile: 820px;
$wp-min-mobile: 782px;
$gf-min-mobile: 640px;
$wp-small: 600px;
$wp-verysmall: 480px;
