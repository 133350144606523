@font-face {
    font-family: 'genericabold';
    src: url('css/fonts/generica_bold.woff2') format('woff2'),
         url('css/fonts/generica_bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'genericaregular';
    src: url('css/fonts/generica.woff2') format('woff2'),
         url('css/fonts/generica.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'jenna_sueregular';
    src: url('css/fonts/jennasue.woff2') format('woff2'),
         url('css/fonts/jennasue.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}