@import 'fonts/fonts';
@import 'skin/mixins';
@import 'skin/variables';

body.login {
    position: relative;
    background-color: transparentize($black2,0.3);
}

.login h1 a{
    max-width: 100%;
    margin-bottom: 10px;
    z-index: 10;
    position: relative;
    outline: none!important;
    box-shadow: none!important;
}
.login div#login {
    width: 420px;
    max-width: 100%;
    padding-top: 4%!important;
}

form#loginform {
    padding: 28px 24px 50px;
    background-color: $white;
    border: 1px solid rgba(0,0,0,0.05);
    box-shadow: 8px 8px 20px rgba(0,0,0,0.25);
    margin-top: 0;
}

.login label, .login form .forgetmenot label {
    color: $black2;
    font-weight: normal;
    text-transform: uppercase;;
    font-size: 14px;
    font-family: $arial;
}

.login form .forgetmenot label {
    text-transform: uppercase;
    font-size: 16px;
    font-family: $arial;
}

.login input[type="text"], 
.login input[type="password"] {
    background-color: $white;
    color: $black2;
    padding: 8px 12px;
    font-size: 16px;
    font-family: $arial;
    border: 1px solid $black3;
    margin: 7px 6px 26px 0;    
    box-shadow: none;
}
.login input{
    outline: none!important;
}
.wp-core-ui .button-group.button-large .button, .wp-core-ui .button.button-large {
    position: absolute;
    display: block;
    font-size: 22px;
    font-family: $generica;
    line-height: 26px!important;
    min-height: 0!important;
    padding: 7px 14px!important;
    border: 0;
    color: $white;
    text-transform: uppercase;
    background-color: $blue;
    text-decoration: none;
    text-shadow: none!important;
    outline: none!important;
    @include border-radius(25px);
    @include letter-spacing(60);
    font-weight: 700;
    width: 200px;
    max-width: 80%;
    height: 50px;
    cursor: pointer;
    margin: 46px auto;
    left: 0;
    right: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.wp-core-ui .button-group.button-large .button:hover, .wp-core-ui .button.button-large:hover {
    background: $pink;
    color: $white;
}

.wp-core-ui .button-primary {
    outline: 0 !important;
    box-shadow: 0 3px 6px rgba(0,0,0,0.2);
    background: none;
}

p#nav {
  display: none;
}

p#backtoblog {
  display: none;
}

#login form p.submit {
    text-align: center;
}

.login #login_error, .login .message {
    border-left: 0;
    text-align: center;
    background-color: transparent;
    color: inherit;
    font-family: $arial;
    font-size: 14px;
    position: absolute;
    left: 0;
    right: 0;
    margin-top: 320px;
    box-shadow: none; 
    color: $red;   
}

input[type=text]:focus, input[type=search]:focus, input[type=radio]:focus, input[type=tel]:focus, input[type=time]:focus, input[type=url]:focus, input[type=week]:focus, input[type=password]:focus, input[type=checkbox]:focus, input[type=color]:focus, input[type=date]:focus, input[type=datetime]:focus, input[type=datetime-local]:focus, input[type=email]:focus, input[type=month]:focus, input[type=number]:focus, select:focus, textarea:focus {
    box-shadow: none;
}

.wp-core-ui .button-primary.active, .wp-core-ui .button-primary.active:focus, .wp-core-ui .button-primary.active:hover, .wp-core-ui .button-primary:active {
    box-shadow: none;
}

.wp-core-ui .button-primary.focus, .wp-core-ui .button-primary:focus {
    box-shadow: none;
}
input[type=checkbox]:focus{
    border-color: $blue;
}
input[type=checkbox]:checked:before{
    color: $blue;
}